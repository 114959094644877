import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button, Space, Table, Tag, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import GlobalContainer from '../../components/global-container';
import { Context } from '../../context/context';
import CustomModal from '../../components/modal';
import { addMenu } from '../../redux/slices/menu';

const Coupon = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsModalVisible } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [couponId, setCouponId] = useState(null);

  // Expanded Operator Categories
  const OPERATOR_CATEGORIES = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Prepaid' },
    { value: 2, label: 'DTH' },
    { value: 3, label: 'Electricity' },
    { value: 4, label: 'Broadband' },
    { value: 5, label: 'Gas - Piped Gas' },
    { value: 6, label: 'Fastag' },
    { value: 7, label: 'Landline' },
    { value: 8, label: 'Postpaid' },
    { value: 9, label: 'Broadband Postpaid' },
    { value: 10, label: 'Insurance' },
    { value: 11, label: 'EMI Payment' },
    { value: 12, label: 'LPG Booking' },
  ];

  // Fetch coupons
  const fetchCoupons = useCallback(async () => {
    const token = localStorage.getItem('token');
    setLoading(true);
    try {
      const response = await axios.get('https://api.givzo.in/public/api/v1/dashboard/admin/coupons', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.data.success) {
        setData(response.data.data);
        toast.success(t('fetch.success'));
      }
    } catch (error) {
      console.error('Fetch Error:', error);
      toast.error(t('error.fetching'));
    } finally {
      setLoading(false);
    }
  }, [t]);

  // Delete coupon
  const deleteCoupon = useCallback(async () => {
    try {
      await axios.delete(`https://api.givzo.in/api/v1/dashboard/admin/coupons/${couponId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      toast.success(t('successfully.deleted'));
      setCouponId(null);
      setIsModalVisible(false);
      fetchCoupons();
    } catch (error) {
      console.error('Delete Error:', error);
      toast.error(t('error.deleting'));
    }
  }, [couponId, setIsModalVisible, fetchCoupons, t]);

  // Navigation to edit page
  const goToEdit = useCallback((row) => {
    dispatch(
      addMenu({
        url: `coupon/${row.id}`,
        id: 'coupon_edit',
        name: t('edit.coupon'),
      })
    );
    navigate(`/coupon/${row.id}`);
  }, [dispatch, navigate, t]);

  // Get category label
  const getCategoryLabel = useCallback((cat) => {
    const category = OPERATOR_CATEGORIES.find(c => c.value === cat);
    return category ? category.label : 'Unknown';
  }, []);

  // Get category color
  const getCategoryColor = useCallback((cat) => {
    const colorMap = {
      0: 'blue',     // All
      1: 'green',    // Prepaid
      2: 'purple',   // DTH
      3: 'red',      // Electricity
      4: 'orange',   // Broadband
      5: 'cyan',     // Gas
      6: 'magenta',  // Fastag
      7: 'geekblue', // Landline
      8: 'volcano',  // Postpaid
      9: 'gold',     // Broadband Postpaid
      10: 'lime',    // Insurance
      11: 'pink',    // EMI Payment
      12: 'default'  // LPG Booking
    };
    return colorMap[cat] || 'default';
  }, []);

  // Columns definition
  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      width: 50,
      render: (_, __, index) => index + 1,
    },
    {
      title: t('name'),
      dataIndex: 'name',
      width: 150,
      render: (name) => (
        <Tooltip title={name}>
          {name.length > 20 ? `${name.slice(0, 20)}...` : name}
        </Tooltip>
      ),
    },
    {
      title: t('description'),
      dataIndex: 'description',
      ellipsis: true,
      render: (description) => (
        <Tooltip title={description}>
          {description.length > 30 ? `${description.slice(0, 30)}...` : description}
        </Tooltip>
      ),
    },
    {
      title: t('category'),
      dataIndex: 'cat',
      render: (cat) => (
        <Tag color={getCategoryColor(cat)}>
          {getCategoryLabel(cat)}
        </Tag>
      ),
    },
    {
      title: t('type'),
      dataIndex: 'type',
      render: (type) => type.toUpperCase(),
    },
    {
      title: t('Price/%'),
      dataIndex: 'price',
      render: (price, record) => {
        return record.type.toLowerCase() === 'percent' 
          ? `${price}%` 
          : `₹${price.toFixed(2)}`;
      },
    },
    {
      title: t('quantity'),
      dataIndex: 'qty',
    },
    {
      title: t('expired.at'),
      dataIndex: 'expired_at',
      render: (expired_at) => moment(expired_at).format('YYYY-MM-DD'),
    },
    {
      title: t('options'),
      dataIndex: 'options',
      render: (_, row) => (
        <Space>
          <Tooltip title={t('edit')}>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => goToEdit(row)}
            />
          </Tooltip>
          <Tooltip title={t('delete')}>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setCouponId(row.id);
                setIsModalVisible(true);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  // Fetch coupons on component mount
  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  return (
    <GlobalContainer
      headerTitle={t('coupons')}
      navLInkTo='/coupon/add'
      buttonTitle={t('add.coupon')}
    >
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{
          total: data.length,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `Total ${total} coupons`,
        }}
        loading={loading}
      />
      <CustomModal click={deleteCoupon} text={t('delete.coupon')} />
    </GlobalContainer>
  );
};

export default Coupon;