import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Col, Form, Input, Row, Space } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { disableRefetch, setMenuData } from '../../redux/slices/menu';
import privacyService from '../../services/privacy';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/loading';

export default function Policy() {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  function fetchPolicy() {
    setLoading(true);
    privacyService
      .getPolicy()
      .then(({ data }) => form.setFieldsValue(data))
      .finally(() => {
        setLoading(false);
        dispatch(disableRefetch(activeMenu));
      });
  }

  useEffect(() => {
    if (activeMenu.refetch) {
      fetchPolicy();
    }
  }, [activeMenu.refetch]);

  const onFinish = (values) => {
    const body = {
      ...values,
    };
    console.log('body => ', body);
    setLoadingBtn(true);
    privacyService
      .createPolicy(body)
      .then(() => {
        toast.success(t('successfully.saved'));
      })
      .finally(() => setLoadingBtn(false));
  };

  return (
    <Card title={t('policy')}>
      {!loading ? (
        <Form
          name='policy-form'
          layout='vertical'
          onFinish={onFinish}
          form={form}
          initialValues={activeMenu.data}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={t('title')}
                name='title'
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('description')}
                name='description'
                valuePropName='data'
                getValueFromEvent={(event, editor) => {
                  const data = editor.getData();
                  return data;
                }}
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                ]}
              >
                <CKEditor editor={ClassicEditor} />
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <Button type='primary' htmlType='submit' loading={loadingBtn}>
              {t('save')}
            </Button>
          </Space>
        </Form>
      ) : (
        <Loading />
      )}
    </Card>
  );
}
