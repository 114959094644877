import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../assets/css/product_image.css';
import '../../assets/scss/components/product-categories.scss';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { InputNumber, Button, Table, Image, Card, Space, Switch, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { export_url, IMG_URL } from '../../configs/app-global';
import { Excel } from 'antd-table-saveas-excel';
import { Context } from '../../context/context';
import CustomModal from '../../components/modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addMenu, disableRefetch, setMenuData } from '../../redux/slices/menu';
import productService from '../../services/product';
import { fetchProducts } from '../../redux/slices/product';
import useDidUpdate from '../../helpers/useDidUpdate';
import { DebounceSelect } from '../../components/search';
import brandService from '../../services/brand';
import categoryService from '../../services/category';
import shopService from '../../services/shop';
import SearchInput from '../../components/search-input';
import formatSortType from '../../helpers/formatSortType';
import { useTranslation } from 'react-i18next';
import DeleteButton from '../../components/delete-button';
import axios from 'axios';
import { GIFT_API_TOKEN, GIFT_PRODUCTS_URL } from '../../configs/urls';
import parseJSON from 'date-fns/parseJSON';




const ProductCategories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goToEdit = (row) => {
    dispatch(
      addMenu({
        id: `product-edit`,
        url: `product/${row.uuid}`,
        name: t('edit.product'),
      })
    );
    navigate(`/product/${row.uuid}`);
  };

  // const columns = [
  //   {
  //     title: t('id'),
  //     dataIndex: 'id',
  //     sorter: true,
  //   },
  //   {
  //     title: t('image'),
  //     dataIndex: 'img',
  //     render: (img) => {
  //       return (
  //         <img src={img} width={100} alt='GIVZO' style={{ borderRadius: 4 }} />
  //         //   <Image
  //         //     width={100}
  //         //     src={IMG_URL + img}
  //         //     placeholder
  //         //     style={{ borderRadius: 4 }}
  //         //   />
  //       );
  //     },
  //   },
  //   {
  //     title: t('name'),
  //     dataIndex: 'name',
  //   },
  //   {
  //     title: t('category'),
  //     dataIndex: 'category_name',
  //   },
  //   {
  //     title: t('active'),
  //     dataIndex: 'active',
  //     render: (active, row) => {
  //       return (
  //         <Switch
  //           onChange={() => {
  //             setIsModalVisible(true);
  //             setUUID(row.uuid);
  //             setIsDelete(false);
  //           }}
  //           checked={active}
  //         />
  //       );
  //     },
  //   },
  //   {
  //     title: t('options'),
  //     dataIndex: 'options',
  //     render: (data, row) => {
  //       return (
  //         <Space>
  //           <Button
  //             type='primary'
  //             icon={<EditOutlined />}
  //             onClick={() => goToEdit(row)}
  //           />
  //           <DeleteButton
  //             icon={<DeleteOutlined />}
  //             onClick={() => {
  //               setIsModalVisible(true);
  //               setUUID(row.uuid);
  //               setIsDelete(true);
  //             }}
  //           />
  //         </Space>
  //       );
  //     },
  //   },
  // ];


  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: t('image'),
      dataIndex: 'small_image',
      render: (img) => (
        <img src={img} width={100} alt='GIVZO' style={{ borderRadius: 4 }} id="product_image_hover"/>
      ),
    },
    {
      title: t('name'),
      dataIndex: 'name',
    },
    // {
    //   title: t('category'),
    //   dataIndex: 'category_name',
    // },
    {
      title: t('active'),
      dataIndex: 'active',
      render: (active, row) => {
        return (
          <Switch
            onChange={() => {
              setIsModalVisible(true);
              setUUID(row.id);  // Make sure to set UUID here
              setIsDelete(false);
            }}
            checked={active}
          />
        );
      },
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      render: (percentage, row) => (
        <InputNumber
          value={inputValues[row.id] !== undefined ? inputValues[row.id] : percentage}
          onChange={(value) => handlePercentageChange(value, row.id)}
          onFocus={() => handleFocus(row.id)}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
        />
      ),

    },
    // {
    //   title: t('options'),
    //   dataIndex: 'options',
    //   render: (data, row) => {
    //     return (
    //       <Space>
    //         <Button
    //           type='primary'
    //           icon={<EditOutlined />}
    //           onClick={() => goToEdit(row)}
    //         />
    //         <DeleteButton
    //           icon={<DeleteOutlined />}
    //           onClick={() => {
    //             setIsModalVisible(true);
    //             setUUID(row.id);  // Make sure to set UUID here
    //             setIsDelete(true);
    //           }}
    //         />
    //       </Space>
    //     );
    //   },
    // },
  ];
     
    const [catalogProducts, setCatalogProducts] = useState([]);
    // const [filteredProducts, setFilteredProducts] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
  
    useEffect(() => {
      const fetchCatalogProducts = async () => {
        try {
          const response = await fetch('https://api.givzo.in/api/v1/dashboard/quickgift/all-products');
          if (!response.ok) {
            throw new Error(`Failed to fetch data: ${response.status}`);
          }
          const data = await response.json();
          // console.log("catalogProducts = ",catalogProducts);
          setCatalogProducts(data);
          // setFilteredProducts(data);
        } catch (error) {
          console.error('Error fetching catalog products:', error);
        }
      };
  
      fetchCatalogProducts();
    }, []);



   
  
    const handleSearch = (value) => {
      setSearchTerm(value);
    };
  
    const filteredData = catalogProducts.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const [uuid, setUUID] = useState(false);
  const { setIsModalVisible } = useContext(Context);
  const [isDelete, setIsDelete] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [giftapiproducts, setGiftProducts] = useState(null);
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { products, meta, loading, params } = useSelector(
    (state) => state.product,
    shallowEqual
  );


  const fetchgiftproducts = async () => {
    let authtoken = localStorage.getItem(GIFT_API_TOKEN);
    await axios.get(GIFT_PRODUCTS_URL + authtoken).then((response) => {
      let giftproducts = response.data['products'];
      let data = [];
      giftproducts.map((item, index) => {
        let model = {
          id: index,
          name: item.name,
          price: item.currency.symbol + item.minPrice,
          active: true,
          category_name: 'Demo Category',
          img:
            item.images.base !== ''
              ? item.images.base
              : 'https://api.givzo.in/storage/images/users/1-1668423628.png',
        };
        data.push(model);
      });
      // console.log(data);

      setGiftProducts(data);
    });
  };

  
  

  const goToImport = () => {
    dispatch(
      addMenu({
        id: 'product-import',
        url: `catalog/product/import`,
        name: t('product.import'),
      })
    );
    navigate(`/catalog/product/import`);
  };

  const productDelete = () => {
    setLoadingBtn(true);
    productService
      .delete(uuid)
      .then(() => {
        setIsModalVisible(false);
        toast.success(t('successfully.deleted'));
        dispatch(fetchProducts(params));
      })
      .finally(() => setLoadingBtn(false));
  };

  // const handleActive = () => {
  //   setLoadingBtn(true);
  //   productService
  //     .setActive(uuid)  // Ensure uuid is correctly passed here
  //     .then(() => {
  //       setIsModalVisible(false);
  //       dispatch(fetchProducts(params));
  //       toast.success(t('successfully.updated'));
  //     })
  //     .finally(() => setLoadingBtn(false));
  // };

  const handleActive = () => {
    setLoadingBtn(true);
    productService
      .setActive(uuid)  // Ensure uuid is correctly passed here
      .then(() => {
        setCatalogProducts((prevProducts) => 
          prevProducts.map(product => 
            product.id === uuid ? { ...product, active: !product.active } : product
          )
        );
        setIsModalVisible(false);
        toast.success(t('successfully.updated'));
      })
      .finally(() => setLoadingBtn(false));
  };



  

  // for percentage
  const debounceTimeout = useRef(null);

  const handlePercentageChange = (value, id) => {
    if (value === undefined || value === '') {
      value = 0;
    }

    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      console.log(`Percentage changed for ID ${id} to ${value}%`);
      updatePercentage(id, value);
    }, 2000); // Adjust the delay as needed
  };


  const handleFocus = (id) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: '',
    }));
  };


  const updatePercentage = (uuid, percentage) => {
    setLoadingBtn(true);
    productService.updatePercentage(uuid, percentage)
      .then(() => {
        setCatalogProducts((prevProducts) => 
          prevProducts.map(product => 
            product.id === uuid ? { ...product, percentage } : product
          )
        );
        toast.success('Successfully updated percentage');
      })
      .finally(() => setLoadingBtn(false));
  };
  

  function onChangePagination(pagination, filters, sorter) {
    const { pageSize: perPage, current: page } = pagination;
    const { field: column, order } = sorter;
    const sort = formatSortType(order);
    dispatch(
      setMenuData({ activeMenu, data: { perPage, page, column, sort } })
    );
  }

  useDidUpdate(() => {
    const data = activeMenu.data;
    const paramsData = {
      search: data?.search,
      brand_id: data?.brand?.value,
      category_id: data?.category?.value,
      shop_id: data?.shop?.value,
      sort: data?.sort,
      column: data?.column,
      perPage: data?.perPage,
      page: data?.page,
    };
    dispatch(fetchProducts(paramsData));
  }, [activeMenu.data]);

  useEffect(() => {
    if (activeMenu.refetch) {
      dispatch(fetchProducts());
      dispatch(disableRefetch(activeMenu));
    }
  }, [activeMenu.refetch]);


  const excelExport = () => {
    setDownloading(true);
    const body = {
      shop_id: activeMenu.data.shop.value,
    };
    productService
      .export(body)
      .then((res) => {
        const body = export_url + res.data.file_name;
        window.location.href = body;
      })
      .finally(() => setDownloading(false));
  };
  

  const goToAddProduct = () => {
    dispatch(
      addMenu({
        id: 'product-add',
        url: 'product/add',
        name: t('add.product'),
      })
    );
    navigate('/product/add');
  };

  async function fetchBrands(search) {
    return brandService.search(search).then(({ data }) =>
      data.map((item) => ({
        label: item.title,
        value: item.id,
      }))
    );
  }

  async function fetchCategories(search) {
    const params = { search };
    return categoryService.search(params).then(({ data }) =>
      data.map((item) => ({
        label: item.translation?.title,
        value: item.id,
      }))
    );
  }

  async function fetchShops(search) {
    const params = { search };
    return shopService.search(params).then(({ data }) =>
      data.map((item) => ({
        label: item.translation?.title,
        value: item.id,
      }))
    );
  }

  useEffect(() => {
    if (localStorage.getItem(GIFT_API_TOKEN) !== null) {
      fetchgiftproducts();
    }
  }, []);

  //console.log('activeMenu', activeMenu?.data?.shop);

  

  return (
    <Card
      title={t('products')}
      extra={
        <Space>
          {/* <DebounceSelect
            placeholder={t('select.shop')}
            fetchOptions={fetchShops}
            style={{ minWidth: 150 }}
            onChange={(shop) => handleFilter(shop, 'shop')}
            value={activeMenu.data?.shop}
          /> */}
          {/* <DebounceSelect
            placeholder={t('select.category')}
            fetchOptions={fetchCategories}
            style={{ minWidth: 150 }}
            onChange={(category) => handleFilter(category, 'category')}
            value={activeMenu.data?.category}
          /> */}
          {/* <DebounceSelect
            placeholder={t('select.brand')}
            fetchOptions={fetchBrands}
            style={{ minWidth: 150 }}
            onChange={(brand) => handleFilter(brand, 'brand')}
            value={activeMenu.data?.brand}
          /> */}
          {/* <Button type='primary' onClick={goToAddProduct}>
            {t('add.product')}
          </Button> */}
          {/* <Tooltip title={t('select.shop')}>
            <Button
              onClick={excelExport}
              loading={downloading}
              disabled={!activeMenu?.data?.shop}
            >
              {t('export')}
            </Button>
          </Tooltip> */}
          {/* <Button onClick={goToImport}>{t('import')}</Button> */}
          <Button>{t('Sync')}</Button>
        </Space>
      }
    >
      {/* <div className='d-flex justify-content-end'>
        <SearchInput
          placeholder={t('search')}
          handleChange={(search) => handleFilter(search, 'search')}
        />
      </div> */}
      <div className='d-flex justify-content-end mb-2'>
        <SearchInput handleChange={handleSearch} />
      </div>

      {/* <Table
        rowSelection
        loading={loading}
        columns={columns}
        dataSource={giftapiproducts}
        // pagination={{
        //   pageSize: params.perPage,
        //   page: params.page,
        //   total: meta.total,
        //   defaultCurrent: params.page,
        // }}
        onChange={onChangePagination}
        rowKey={(record) => record.id}
      /> */}


      
{/* <Table columns={columns} dataSource={catalogProducts} rowKey={(record) => record.id} onChange={onChangePagination} /> */}
<Table
        columns={columns}
        dataSource={filteredData}
        rowKey={(record) => record.id}
        onChange={onChangePagination}
      />





      <CustomModal
        click={isDelete ? productDelete : handleActive}
        text={isDelete ? t('delete.product') : t('set.active.product')}
        loading={loadingBtn}
      />
    </Card>
  );
};

export default ProductCategories;
