import React, { useContext, useEffect, useState } from 'react';
import {
  Form,
  Input,
  DatePicker,
  Select,
  Card,
  Button,
  Row,
  Col,
  InputNumber,
  message,
} from 'antd';
import LanguageList from '../../components/language-list';
import { Context } from '../../context/context';
import { useNavigate, useParams } from 'react-router-dom';
import couponService from '../../services/coupon';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeFromMenu } from '../../redux/slices/menu';
import { useTranslation } from 'react-i18next';

const CouponAdd = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { defaultLang, setDefaultLang } = useContext(Context);
  const [names, setNames] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [nameExists, setNameExists] = useState(false);
  const [shops, setShops] = useState([]);
  const [couponType, setCouponType] = useState('fix');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);

  // Expanded Operator Categories
  const OPERATOR_CATEGORIES = [
    { value: '0', label: 'All' },
    { value: '1', label: 'Prepaid' },
    { value: '2', label: 'DTH' },
    { value: '3', label: 'Electricity' },
    { value: '4', label: 'Broadband' },
    { value: '5', label: 'Gas - Piped Gas' },
    { value: '6', label: 'Fastag' },
    { value: '7', label: 'Landline' },
    { value: '8', label: 'Postpaid' },
    { value: '9', label: 'Broadband Postpaid' },
    { value: '10', label: 'Insurance' },
    { value: '11', label: 'EMI Payment' },
    { value: '12', label: 'LPG Booking' },
  ];

  // Fetch shops
  const fetchShops = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://api.givzo.in/api/v1/dashboard/admin/shops', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const result = await response.json();
      if (result.status && result.data) {
        setShops(result.data);
      }
    } catch (error) {
      message.error(t('error_fetching_shops'));
    }
  };

  // Check if coupon name exists
  const checkCouponName = async (name) => {
    try {
      const response = await couponService.checkName(name);
      return response.exists;
    } catch (error) {
      console.error('Error checking coupon name:', error);
      return false;
    }
  };

  // Fetch coupon details for editing
  async function fetchCoupon() {
    setLoading(true);
    try {
      const { data } = await couponService.getById(id);
      setData(data);
      setCouponType(data.type);
      const namesArray = { ...names };
      data.translations?.forEach((item) => {
        namesArray[item.locale] = item.title;
      });
      form.setFieldsValue({
        name: data.name,
        shop_id: data.shop_id,
        type: data.type,
        description: data.description,
        qty: data.qty,
        cat: data.cat.toString(), // Ensure it's a string for Select
        [data.type === 'fix' ? 'price' : 'percent']: data.price,
        expired_at: moment(data.expired_at, 'YYYY-MM-DD'),
      });
      setNames(namesArray);
    } catch (error) {
      message.error(t('error_fetching_coupon'));
    } finally {
      setLoading(false);
    }
  }

  // Language change handler
  const onChangeLanguage = (value) => {
    setDefaultLang(value);
    form.setFieldsValue({
      title: names[value],
    });
  };

  // Name change handler
  const onChangeName = async (e) => {
    const value = e.target.value;
    if (value) {
      const exists = await checkCouponName(value);
      setNameExists(exists && (!id || (id && data.name !== value)));
      if (exists && (!id || (id && data.name !== value))) {
        form.setFields([
          {
            name: 'name',
            errors: [t('coupon_name_exists')],
          },
        ]);
      }
    }
  };

  // Coupon type change handler
  const handleTypeChange = (value) => {
    setCouponType(value);
    // Clear the price/percent field when switching types
    form.setFieldsValue({
      price: undefined,
      percent: undefined,
    });
  };

  // Form submission handler
  const onFinish = async (values) => {
    if (nameExists) {
      message.error(t('coupon_name_exists'));
      return;
    }

    setLoadingBtn(true);
    const params = {
      ...values,
      expired_at: moment(values.expired_at).format('YYYY-MM-DD'),
      qty: Number(values.qty),
      price: values.type === 'fix' ? Number(values.price) : Number(values.percent),
      cat: Number(values.cat),
    };

    try {
      if (id) {
        await couponService.update(id, params);
        message.success(t('coupon_updated_successfully'));
      } else {
        await couponService.create(params);
        message.success(t('coupon_created_successfully'));
      }
      const nextUrl = 'coupons';
      dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
      navigate(`/${nextUrl}`);
    } catch (error) {
      message.error(error.response?.data?.message || t('error_saving_coupon'));
    } finally {
      setLoadingBtn(false);
    }
  };

  // Form validation failure handler
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error(t('please_fill_all_required_fields'));
  };

  // Effect to fetch shops and coupon details
  useEffect(() => {
    fetchShops();
    if (id) {
      fetchCoupon();
    }
  }, [id]);

  return (
    <Card
      title={id ? t('edit.coupon') : t('add.coupon')}
      extra={
        <LanguageList value={defaultLang} onChangeLanguage={onChangeLanguage} />
      }
      loading={loading}
    >
      <Form
        form={form}
        name='coupon-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateTrigger='onBlur'
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={t('Select shop')}
              name='shop_id'
              rules={[{ required: true, message: t('required') }]}
            >
              <Select
                placeholder={t('select_shop')}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {shops.map((shop) => (
                  <Select.Option key={shop.id} value={shop.id}>
                    {shop.translation.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('name')}
              name='name'
              rules={[
                { required: true, message: t('required') },
                { min: 2, message: t('min_length_2') },
                { max: 50, message: t('max_length_50') },
              ]}
              validateStatus={nameExists ? 'error' : ''}
              help={nameExists ? t('coupon_name_exists') : ''}
            >
              <Input onChange={onChangeName} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('type')}
              name='type'
              rules={[{ required: true, message: t('required') }]}
            >
              <Select onChange={handleTypeChange}>
                <Select.Option value='fix'>{t('fix')}</Select.Option>
                <Select.Option value='percent'>{t('percent')}</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='expired_at'
              label={t('expired.at')}
              rules={[{ required: true, message: t('required') }]}
            >
              <DatePicker
                className='w-100'
                disabledDate={(current) => moment().add(-1, 'days') >= current}
                format='YYYY-MM-DD'
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('quantity')}
              name='qty'
              rules={[
                { required: true, message: t('required') },
                { type: 'number', min: 1, message: t('min_quantity_1') },
              ]}
            >
              <InputNumber className='w-100' min={1} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('category')}
              name='cat'
              rules={[{ required: true, message: t('required') }]}
            >
              <Select>
                {OPERATOR_CATEGORIES.map((category) => (
                  <Select.Option key={category.value} value={category.value}>
                    {t(category.label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('description')}
              name='description'
              rules={[
                { required: true, message: t('required') },
                { max: 200, message: t('max_length_200') },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col span={12}>
            {couponType === 'fix' ? (
              <Form.Item
                label={t('price')}
                name='price'
                rules={[
                  { required: true, message: t('required') },
                  { type: 'number', min: 0, message: t('min_price_0') },
                ]}
              >
                <InputNumber className='w-100' min={0} step={0.01} />
              </Form.Item>
            ) : (
              <Form.Item
                label={t('percent')}
                name='percent'
                rules={[
                  { required: true, message: t('required') },
                  { type: 'number', min: 1, message: t('min_percent_1') },
                  { type: 'number', max: 90, message: t('max_percent_90') },
                ]}
              >
                <InputNumber
                  className='w-100'
                  min={1}
                  max={90}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={loadingBtn}>
            {id ? t('update') : t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CouponAdd;