import request from './request';

const productService = {
  getAll: (params) =>
    request.get('dashboard/admin/products/paginate', { params }),
  getById: (id, params) =>
    
    request.get(`dashboard/admin/products/${id}`, { params }),
  create: (params) => request.post(`dashboard/admin/products`, {}, { params }),
  update: (uuid, params) =>
    request.put(`dashboard/admin/products/${uuid}`, {}, { params }),
  delete: (uuid) => request.delete(`dashboard/admin/products/${uuid}`),
  extras: (uuid, data) =>
    request.post(`dashboard/admin/products/${uuid}/extras`, data),
  stocks: (uuid, data) =>
    request.post(`dashboard/admin/products/${uuid}/stocks`, data),
  properties: (uuid, data) =>
    request.post(`dashboard/admin/products/${uuid}/properties`, data),
  setActive: (uuid) =>
    request.post(`dashboard/admin/products/${uuid}/active`, {}),
  updatePercentage: (uuid, percentage) =>
    request.post(`dashboard/admin/products/${uuid}/percentage/${percentage}`, {}),
  export: (params) => request.get(`dashboard/admin/products/export`, {params}),
  import: (data) => request.post('dashboard/admin/products/import', data, {}),
};

export default productService;
