import request from './request';

const privacyService = {
  getPolicy: () => request.get('dashboard/admin/privacy/policy'),
  getTerms: () => request.get('dashboard/admin/privacy/terms'),
  createPolicy: (data) => request.post('dashboard/admin/privacy/policy', data),
  createTerms: (data) => request.post('dashboard/admin/privacy/terms', data),
};

export default privacyService;
