export const BASE_URL = 'https://api.givzo.in';
export const WEBSITE_URL = 'https://givzo.in';
export const api_url = BASE_URL + '/api/v1/';
export const export_url = BASE_URL + '/storage/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = BASE_URL + '/storage/images/';
export const MAP_API_KEY = 'AIzaSyCuDSljHjF4pKT9yJWtnJgsCXf6rxi40Tg';
export const VAPID_KEY =
  'BGpdMJfUm3JOga7NI_4k55vUoSnfj1UNKexk90i4mmRh1o8FzjKHvJfQih9HaoZP0wey-vjmk75llZeNvkk1EvM';
