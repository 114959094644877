import React, { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Image,
  Tag,
  Button,
  Space,
  Descriptions,
  Row,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import orderService from '../../services/order';
import getImage from '../../helpers/getImage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addMenu, disableRefetch, setMenuData } from '../../redux/slices/menu';
import OrderStatusModal from './orderStatusModal';
import OrderDeliveryman from './orderDeliveryman';
import { fetchDeliverymans } from '../../redux/slices/deliveryman';
import { useTranslation } from 'react-i18next';
import numberToPrice from '../../helpers/numberToPrice';
import { clearOrder } from '../../redux/slices/order';

export default function OrderDetails() {
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { allShops: shops } = useSelector(
    (state) => state.allShops,
    shallowEqual
  );
  const { defaultCurrency } = useSelector(
    (state) => state.currency,
    shallowEqual
  );
  const data = activeMenu.data;
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderDeliveryDetails, setOrderDeliveryDetails] = useState(null);

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('shop.name'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, row) => (
        <div>
          {status === 'new' ? (
            <Tag color='blue'>{t(status)}</Tag>
          ) : status === 'canceled' ? (
            <Tag color='error'>{t(status)}</Tag>
          ) : (
            <Tag color='cyan'>{t(status)}</Tag>
          )}
          {status !== 'delivered' && status !== 'canceled' ? (
            <EditOutlined onClick={() => setOrderDetails(row)} />
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      title: t('deliveryman'),
      dataIndex: 'deliveryman',
      key: 'deliveryman',
      render: (deliveryman, row) => (
        <div>
          {row.status === 'ready' ? (
            <Button type='link' onClick={() => setOrderDeliveryDetails(row)}>
              <Space>
                {deliveryman
                  ? `${deliveryman.firstname} ${deliveryman.lastname}`
                  : t('add.deliveryman')}
                <EditOutlined />
              </Space>
            </Button>
          ) : (
            <div>
              {deliveryman?.firstname} {deliveryman?.lastname}
            </div>
          )}
        </div>
      ),
    },
    {
      title: t('delivery.type'),
      dataIndex: 'delivery_type',
      key: 'delivery_type',
      render: (delivery_type) => delivery_type?.translation?.title,
    },
    {
      title: t('delivery.date.&.time'),
      dataIndex: 'delivery',
      key: 'delivery',
      render: (delivery, row) => (
        <div>
          {row.delivery_date} {row.delivery_time}
        </div>
      ),
    },
    {
      title: t('amount'),
      dataIndex: 'price',
      key: 'price',
      render: (price, row) =>
        numberToPrice(price + (row.coupon?.price ?? 0), defaultCurrency.symbol),
    },
    {
      title: t('shop.tax'),
      dataIndex: 'tax',
      key: 'tax',
      render: (tax) => numberToPrice(tax, defaultCurrency.symbol),
    },
    {
      title: t('delivery.fee'),
      dataIndex: 'delivery_fee',
      key: 'delivery_fee',
      render: (delivery_fee) =>
        numberToPrice(delivery_fee, defaultCurrency.symbol),
    },
    {
      title: t('coupon'),
      dataIndex: 'coupon',
      key: 'coupon',
      render: (coupon) => numberToPrice(coupon?.price, defaultCurrency.symbol),
    },
    {
      title: t('payment.status'),
      dataIndex: 'tax',
      key: 'tax',
      render: (tax, row) =>
        row.transaction ? (
          <div>
            {row.transaction?.status === 'progress' ? (
              <Tag color='gold'>{t(row.transaction?.status)}</Tag>
            ) : row.transaction?.status === 'rejected' ? (
              <Tag color='error'>{t(row.transaction?.status)}</Tag>
            ) : (
              <Tag color='cyan'>{t(row.transaction?.status)}</Tag>
            )}
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: t('total.amount'),
      dataIndex: 'price',
      key: 'price',
      render: (price, row) =>
        numberToPrice(
          price + row.tax + row.delivery_fee,
          defaultCurrency.symbol
        ),
    },
  ];

  const handleCloseModal = () => {
    setOrderDetails(null);
    setOrderDeliveryDetails(null);
  };

  function fetchOrder() {
    setLoading(true);
    orderService
      .getById(id)
      .then(({ data }) => {
        const currency = data.currency;
        const user = data.user;
        const id = data.id;
        const price = data.price;
        const cashback = data.order_point?.price;
        const createdAt = data.created_at;
        const details = data.details.map((item) => ({
          title: shops.find((el) => el.id === item.shop_id)?.translation?.title,
          ...item,
        }));
        dispatch(
          setMenuData({
            activeMenu,
            data: { details, currency, user, id, createdAt, price, cashback },
          })
        );
      })
      .finally(() => {
        setLoading(false);
        dispatch(disableRefetch(activeMenu));
      });
  }

  useEffect(() => {
    if (activeMenu.refetch) {
      fetchOrder();
      dispatch(fetchDeliverymans());
    }
  }, [activeMenu.refetch]);

  function getImageFromStock(stock) {
    const stockImage = stock.extras.find((item) => item.group.type === 'image');
    if (!!stockImage) {
      return stockImage.value;
    }
    return stock.product.img;
  }

  const expandedRowRender = (row) => {
    const columns = [
      {
        title: t('id'),
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: t('product.name'),
        dataIndex: 'stock',
        key: 'stock',
        render: (stock) => stock?.product?.translation?.title,
      },
      {
        title: t('image'),
        dataIndex: 'img',
        key: 'img',
        render: (img, row) => (
          <Image
            src={getImage(getImageFromStock(row.stock))}
            alt='product'
            width={100}
            height='auto'
            className='rounded'
            preview
            placeholder
            key={img + row.id}
          />
        ),
      },
      {
        title: t('price'),
        dataIndex: 'origin_price',
        key: 'origin_price',
        render: (origin_price) =>
          numberToPrice(origin_price, defaultCurrency.symbol),
      },
      {
        title: t('quantity'),
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: t('discount'),
        dataIndex: 'discount',
        key: 'discount',
        render: (discount, row) =>
          numberToPrice(discount / row.quantity, defaultCurrency.symbol),
      },
      {
        title: t('tax'),
        dataIndex: 'tax',
        key: 'tax',
        render: (tax, row) =>
          numberToPrice(tax / row.quantity, defaultCurrency.symbol),
      },
      {
        title: t('total.price'),
        dataIndex: 'total_price',
        key: 'total_price',
        render: (total_price) =>
          numberToPrice(total_price, defaultCurrency.symbol),
      },
    ];
    const dataSource = row.order_stocks;

    return (
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey={(record) => record.id}
      />
    );
  };

  const goToEdit = () => {
    dispatch(clearOrder());
    dispatch(
      addMenu({
        url: `order/${id}`,
        id: 'order_edit',
        name: t('edit.order'),
      })
    );
    navigate(`/order/${id}`);
  };

  return (
    <Card
      title={`${t('order.details')} ${data?.id ? `#${data?.id}` : ''}`}
      extra={
        <Button type='primary' icon={<EditOutlined />} onClick={goToEdit}>
          {t('edit')}
        </Button>
      }
    >
      <Row hidden={loading} className='mb-3'>
        <Descriptions>
          <Descriptions.Item label={t('client')} span={3}>
            {data?.user?.firstname} {data?.user?.lastname}
          </Descriptions.Item>
          <Descriptions.Item label={t('phone')} span={3}>
            {data?.user?.phone}
          </Descriptions.Item>
          <Descriptions.Item label={t('email')} span={3}>
            {data?.user?.email}
          </Descriptions.Item>
          <Descriptions.Item label={t('address')} span={3}>
            {data?.details[0]?.delivery_address?.address}
          </Descriptions.Item>
          <Descriptions.Item label={t('created.at')} span={3}>
            {data?.createdAt}
          </Descriptions.Item>
          <Descriptions.Item label={t('payment.type')} span={3}>
            {t(data?.details[0]?.transaction?.payment_system?.tag)}
          </Descriptions.Item>
          <Descriptions.Item label={t('amount')} span={3}>
            {numberToPrice(data?.price, defaultCurrency.symbol)}
          </Descriptions.Item>
          <Descriptions.Item label={t('cashback')} span={3}>
            {numberToPrice(data?.cashback, defaultCurrency.symbol)}
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Table
        columns={columns}
        dataSource={activeMenu.data?.details || []}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ['0'],
        }}
        loading={loading}
        rowKey={(record) => record.id}
        pagination={false}
      />
      {orderDetails && (
        <OrderStatusModal
          orderDetails={orderDetails}
          handleCancel={handleCloseModal}
        />
      )}
      {orderDeliveryDetails && (
        <OrderDeliveryman
          orderDetails={orderDeliveryDetails}
          handleCancel={handleCloseModal}
        />
      )}
    </Card>
  );
}
