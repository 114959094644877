import React from 'react';

export default function Footer() {
  return (
    <footer className='footer'>
      <span>
        <span className='font-weight-semibold'>Codenia</span>
        <span>
          {' '}
          support team <a href='mailto:hello@codeniatechnologies.com'>hello@codeniatechnologies.com</a>
        </span>
      </span>
    </footer>
  );
}
