import React, { useMemo, useState } from 'react';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';

const SearchInput = ({ handleChange, ...props }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const debounceSearch = useMemo(() => {
    const delayedSearch = debounce((value) => {
      handleChange(value);
    }, 800);
    return delayedSearch;
  }, [handleChange]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    debounceSearch(value);
  };

  return (
    <Input
      value={searchTerm}
      onChange={handleInputChange}
      prefix={<SearchOutlined />}
      placeholder="Search"
      style={{ width: 200 }}
      {...props}
    />
  );
};

export default SearchInput;
